import React, { useContext, useState, useEffect } from "react"
import { auth } from "../Firebase"
import { firestore } from "../Firebase"


const AuthContext = React.createContext()

export function useAuth() {
    return useContext(AuthContext)
} 


export function AuthProvider({ children }) {

    const [currentUser, setCurrentUser] = useState()
    const [loading, setLoading] = useState(true)

    function signup(email, password){
         auth.createUserWithEmailAndPassword(email, password).then(cred => {
          
            return firestore.collection('users').doc(cred.user.uid).set({
                "email": email,
                "uid": cred.user.uid,
                // name: name,
            })
        })
    }

    function login(email, password){
        return auth.signInWithEmailAndPassword(email, password)
    }

    function logout() {
        return auth.signOut();
    }

    function resetPassword(email){
        return auth.sendPasswordResetEmail(email)
    }

    function updateEmail(email){
        return currentUser.updateEmail(email)
    }

    function updatePassword(password){
        return currentUser.updatePassword(password)
    }

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(user => {
        setCurrentUser(user)
        setLoading(false)
    })

    return unsubscribe
  }, [])

    const value = {
        currentUser,
        signup,
        login,
        logout,
        resetPassword,
        updateEmail,
        updatePassword
    }


  return (
    <AuthContext.Provider value={value}>
    {!loading && children}
  </AuthContext.Provider>
  )
}

